
import { defineComponent, onUnmounted, ref, watch, computed } from "vue";
import BookRow from "@/components/BookRow.vue";
import { refetchBookSearch } from "@/utils/book";
import { useMachine } from "@xstate/vue";
import { searchMachine } from "@/machines/searchMachine";

export default defineComponent({
  setup() {
    const query = ref("");
    const { state: searchState, send: sendSearch } = useMachine(searchMachine);
    const isFetching = computed(() => searchState.value.matches("searching"));
    const isError = computed(() => searchState.value.matches("failure"));
    const error = computed(() => searchState.value.context.message);
    const books = computed(() => searchState.value.context.books);
    const booksRef = computed(() => searchState.value.context.booksRef);
    watch(query, (query) => {
      sendSearch({ type: "UPDATE-QUERY", query });
    });

    function searchBooks() {
      sendSearch({ type: "SEARCH" });
    }

    onUnmounted(() => refetchBookSearch());

    return {
      query,
      isError,
      isFetching,
      books,
      error,
      status,
      searchBooks,
      booksRef,
    };
  },
  components: {
    BookRow,
  },
});
